.option {
    cursor: pointer;
    transition: opacity 0.3s, border 0.3s;
}

.selected {
    border: 2px solid blue;
}

.unselected {
    opacity: 0.5;
}